<template>
  <div class="px-5">
    <div>
      <v-row>
        <v-col cols="12" md="3" sm="6">
          <div class="text-h4 primary--text">การแก้ไขเอกสาร</div>
        </v-col>
        <v-col cols="12" md="3" sm="6">
          <v-text-field
            v-model="filter.start_with"
            prepend-inner-icon="mdi-magnify"
            label="ค้นหา เลขทะเบียน ชื่อลูกค้า เบอร์โทร"
            outlined
            rounded
            dense
            single-line
            hide-details
            @keyup="getData()"
            item-key="_id"
          ></v-text-field>
        </v-col>
        <v-col class="text-right">
          <v-btn
            color="success"
            small
            outlined
            class="px-5"
            @click="download_excel()"
          >
            ดาวน์โหลด
            <v-icon right small>mdi-download</v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <v-card dense flat rounded outlined class="my-5 pt-3 pb-3 px-5">
        <v-row>
          <v-col>
            <v-select
              label="สถานะ"
              v-model="filter.action"
              :items="action"
              item-value="value"
              item-text="text"
              outlined
              rounded
              dense
              hide-details
            >
            </v-select>
          </v-col>
          <v-col>
            <v-select
              label="ช่วงเวลา"
              v-model="timeRangeType"
              :items="timeRangeTypes"
              item-value="value"
              item-text="text"
              outlined
              rounded
              dense
              hide-details
            >
            </v-select>
          </v-col>
          <v-col>
            <DatePicker
              v-if="timeRangeType == 1"
              label="วันจดทะเบียน"
              :value="filter.start_date"
              @onChange="(val) => (filter.start_date = val)"
              hideDetails
              outlined
              rounded
              dense
            />
            <v-menu
              v-else-if="timeRangeType == 2"
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :value="filter.start_date ? getMonth(filter.start_date) : ''"
                  label="เดือน"
                  prepend-icon="mdi-calendar"
                  v-bind="attrs"
                  v-on="on"
                  hideDetails
                  outlined
                  rounded
                  dense
                  readonly
                >
                </v-text-field>
              </template>
              <v-date-picker
                class="hidden-sm-and-down"
                v-model="month"
                type="month"
                no-title
                scrollable
                locale="th"
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="menu = false">Cancel</v-btn>
                <v-btn text color="primary" @click="onSaveMonth(month)">
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>

          <v-col>
            <DatePicker
              v-if="timeRangeType == 1"
              label="ถึงวันที่"
              :value="filter.end_date"
              @onChange="(val) => (filter.end_date = val)"
              hideDetails
              outlined
              rounded
              dense
            />
          </v-col>

          <v-col>
            <v-btn
              color="primary"
              small
              class="float-right mt-1"
              @click="getData()"
            >
              แสดงรายงาน <v-icon right>mdi-magnify</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
      <v-data-table
        class="elevation-2 row-pointer text-body-2"
        :headers="setting.document_approvement ? headers_permission : headers"
        :items="list"
        :search="search"
        :loading="loading"
        :loading-text="$table_loading_text"
        :items-per-page="10"
        hide-default-footer
        item-key="id"
      >
        <template v-slot:[`item.date_of_issue`]="{ item }">
          {{ formatDate(item.date_of_issue) }}
        </template>
        <template v-slot:[`item.number`]="{ item }">
          {{ item.number }}
        </template>
        <template v-slot:[`item.customer_name`]="{ item }">
          {{ item.customer_name }}
        </template>
        <template v-slot:[`item.project_name`]="{ item }">
          {{ item.project_name }}
        </template>
        <template v-slot:[`item.modifier_date`]="{ item }">
          {{ formatDate(item.modifier_date) }}
          {{ item.modifier_date | moment($formatTime) }}
        </template>
        <template v-slot:[`item.note`]="{ item }">
          <span style="white-space: pre;">{{ item.note }}</span>
        </template>
        <template v-slot:[`item.action`]="{ item }">
          {{ item.action == 0 ? 'ลบ' : '' }}
          {{ item.action == 1 ? 'รีเซ็ต' : '' }}
          {{ item.action == 2 ? 'ยกเลิก' : '' }}
          {{ item.action == 3 ? 'แก้ไข' : '' }}
        </template>        
        <template v-slot:[`item.approvement`]="{ item }">
          <MenuShipEditDocument
            small
            v-model="item.approvement"
            v-if="item.action==3"
            :id="item._id"
            :actionId="item.approvement"
            :permission="permission"
            @success="getData()"
          />
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import DatePicker from '@/components/DatePicker.vue';
import MenuShipEditDocument from '@/components/MenuShipEditDocument.vue';
import * as moment from 'moment';
export default {
  components: {
    DatePicker,
    MenuShipEditDocument,
  },
  data: () => ({
    loading: true,
    list: [],
    permission: false,
    setting: null,
    search: '',
    branch_id: '',
    product_list: [],
    headers: [
      { text: 'วันที่', value: 'date_of_issue', sortable: false },
      {
        text: 'เลขที่เอกสาร',
        value: 'number',
        filterable: false,
        sortable: false,
      },
      { text: 'ชื่อลูกค้า', value: 'customer_name', sortable: false },
      {
        text: 'ชื่อโปรเจ็ค',
        value: 'car_plate',
        filterable: false,
        sortable: false,
      },
      {
        text: 'สถานะ',
        value: 'action',
        filterable: false,
        sortable: false,
      },
      {
        text: 'ผู้แก้',
        value: 'user_name',
        filterable: false,
        sortable: false,
      },
      {
        text: 'วันที่แก้ไข/เวลา',
        value: 'modifier_date',
        filterable: false,
        sortable: false,
      },
      {
        text: 'หมายเหตุ',
        value: 'note',
        filterable: false,
        sortable: false,
      },
    ],
    headers_permission: [
      { text: 'วันที่', value: 'date_of_issue', sortable: false },
      {
        text: 'เลขที่เอกสาร',
        value: 'number',
        filterable: false,
        sortable: false,
      },
      { text: 'ชื่อลูกค้า', value: 'customer_name', sortable: false },
      {
        text: 'ชื่อโปรเจ็ค',
        value: 'car_plate',
        filterable: false,
        sortable: false,
      },
      {
        text: 'สถานะ',
        value: 'action',
        filterable: false,
        sortable: false,
      },
      {
        text: 'ผู้แก้',
        value: 'user_name',
        filterable: false,
        sortable: false,
      },
      {
        text: 'วันที่แก้ไข/เวลา',
        value: 'modifier_date',
        filterable: false,
        sortable: false,
      },
      {
        text: 'หมายเหตุ',
        value: 'note',
        filterable: false,
        sortable: false,
      },
      { text: 'สถานะแก้ไข', value: 'approvement', sortable: false },
    ],
    timeRangeTypes: [
      { value: 3, text: 'วันนี้' },
      { value: 0, text: 'เดือนปัจจุบัน' },
      { value: 2, text: 'เดือนก่อน' },
      { value: 1, text: 'เลือกช่วงวันที่' },
    ],
    timeRangeType: 3,
    action: [
      { text: 'ทั้งหมด', value: '' },
      { text: 'ลบ', value: 0 },
      { text: 'รีเซ็ต', value: 1 },
      { text: 'ยกเลิก', value: 2 },
      { text: 'แก้ไข', value: 3 },
    ],
    month: null,
    filter: {
      item_per_page: 10,
      action: '',
      page: 1,
      branch_id: null,
      start_with: '',
      start_date: moment().add(-0, 'days').format('YYYY-MM-DD'),
      end_date: moment().format('YYYY-MM-DD'),
      // month: moment().format("YYYY-MM")
    },
  }),
  created() {
    this.filter.branch_id = this.$store.state.selected_branch._id;
    this.getData();
    this.getSetting();
  },
  methods: {
    async getData() {
      this.loading = true;
      let body = {
        token: this.$jwt.sign(this.filter, this.$privateKey, {
          noTimestamp: true,
        }),
      };
      console.log(this.filter)
      await this.$axios
        .post(`${this.$baseUrl}/document/get_logs`, body)
        .then((res) => {
          console.log(res.result)
          this.list = res.result.data;
          this.permission = res.result.permission;
          this.pageCount = res.result.total_page || 1;
        })
        .catch((err) => {
          console.log('err', err);
          //this.$alertServerError({ title: err.error_message });
        });
      this.loading = false;
    },
    formatDate(date) {
      if (!date) return '';

      let year = parseInt(moment(date).format('YYYY'));
      let m_d = moment(date).format('DD/MM');
      return `${m_d}/${year + 543}`;
    },
    onSaveMonth(date) {
      this.$refs.menu.save(date);
      this.filter.start_date = moment(date, 'YYYY-MM')
        .startOf('month')
        .format('YYYY-MM-DD');
      this.filter.end_date = moment(date, 'YYYY-MM')
        .endOf('month')
        .format('YYYY-MM-DD');
    },
    getMonth(date) {
      return moment(date).format('YYYY-MM');
    },
    async getSetting() {
      let listFiltered = this.$store.state.list.branchs.filter((el) => {
          return el._id == this.$store.state.selected_branch._id;
      });
      this.setting = listFiltered[0].setting;
    },
    async download_excel() {
      this.loading = true;
      let body = {
        token: this.$jwt.sign(this.filter, this.$privateKey, {
          noTimestamp: true,
        }),
      };
      await this.$axios({
        method: 'post',
        url: `${this.$baseUrl}/document/download_logs`,
        responseType: 'blob',
        data: body,
      })
        .then((response) => {
          if (
            moment(this.filter.start_date).format('DDMMYYYY') !=
            moment(this.filter.end_date).format('DDMMYYYY')
          ) {
            this.forceFileDownload(
              response,
              'รายงานการแก้ไขเอกสาร' +
                moment(this.filter.start_date, 'YYYY-MM-DDTHH:mm:ss').format(
                  'DD-MM-YYYY'
                ) +
                '_' +
                moment(this.filter.end_date, 'YYYY-MM-DDTHH:mm:ss').format(
                  'DD-MM-YYYY'
                ) +
                '.xlsx'
            );
          } else {
            this.forceFileDownload(
              response,
              'รายงานการแก้ไขเอกสาร' +
                moment(this.filter.start_date, 'YYYY-MM-DDTHH:mm:ss').format(
                  'DD-MM-YYYY'
                ) +
                '.xlsx'
            );
          }
        })
        .catch((err) => {
          console.log('err', err);
          // this.$alertServerError({ title: err.error_message });
        });
      this.loading = false;
    },
    forceFileDownload(response, title) {
      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', title);
      document.body.appendChild(link);
      link.click();
    },
  },
  watch: {
    timeRangeType: function (value, oldValue) {
      console.log(value);

      if (value == 0) {
        // 0 = เดือนปัจจุบัน
        this.filter.start_date = moment().startOf('month').format('YYYY-MM-DD');
        this.filter.end_date = moment().endOf('month').format('YYYY-MM-DD');
        console.log(this.filter);
      } else if (value == 1) {
        // 1 = เลือกช่วงวันที่
        this.filter.start_date = moment().startOf('month').format('YYYY-MM-DD');
        this.filter.end_date = moment().endOf('month').format('YYYY-MM-DD');
        console.log(this.filter);
      } else if (value == 2) {
        // 2 = เดือนก่อน
        this.filter.start_date = moment()
          .subtract(1, 'months')
          .startOf('month')
          .format('YYYY-MM-DD');
        this.filter.end_date = moment()
          .subtract(1, 'months')
          .endOf('month')
          .format('YYYY-MM-DD');
        this.month = moment().subtract(1, 'months').format('YYYY-MM');
        console.log(this.filter);
      } else if (value == 3) {
        // 3 = วันนี้
        this.filter.start_date = moment().format('YYYY-MM-DD');
        this.filter.end_date = this.filter.start_date;
        console.log(this.filter);
      }
    },
  },
};
</script>